:root {
  --neutral-50: #fafafa;
  --neutral-100: #f5f5f5;
  --neutral-200: #e5e5e5;
  --neutral-300: #d4d4d4;
  --neutral-400: #a3a3a3;
  --neutral-500: #737373;
  --neutral-600: #525252;
  --neutral-700: #404040;
  --neutral-800: #262626;
  --neutral-900: #171717;
}
* {
  /* outline: 1px red solid; */
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* position: relative; */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--neutral-100);
}

form h1 {
  margin-bottom: 16px;
}
form {
  max-width: 625px;
  margin: 0 auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* --   Override Geist Component Styles  -- */

.tabs header .scroll-container {
  padding-left: 0;
}

.tabs header .scroll-container .tab {
  font-size: 16px;
}
